<template>
  <main id="content-pane">
    <div>
      <component :is="component" />
      <copyright />
    </div>
  </main>
</template>

<script>
export default {
  name: "ContentPane",
  components: {
    copyright: require("@skeleton/copyright").default
  },
  computed: {
    component() {
      const matchedRoute = this.$route.matched[this.$route.matched.length - 1];
      const component = matchedRoute.components["default"];
      return component;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@sass/bulma/custom-variables";
#content-pane {
  grid-row: 1;
  grid-column: span 2;
  background: $white;
  padding: 0.75em;
  display: flex;
  @include is-scrollable;
  @include desktop {
    .isWhitelabeled & {
      grid-column: 2;
      padding: 1.5em;
    }
  }
  & > div {
    width: 100%;
    max-width: 30rem;
    margin: auto;
  }
}
</style>
