<template>
  <div id="container">
    <endorsement-pane v-if="!isWhitelabeled" />
    <content-pane />
  </div>
</template>

<script>
export default {
  name: "LoggedOut",
  components: {
    "content-pane": require("./contentPane").default,
    "endorsement-pane": require("./endorsementPane").default
  },
  computed: {
    isWhitelabeled() {
      return this.$store.getters["ui/isWhitelabeled"]();
    }
  },
  mounted() {
    this.$emit("mounted");
  }
};
</script>

<style scoped lang="scss">
@import "~@sass/bulma/custom-variables";

#container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(auto, 28rem);
  grid-gap: 0;
  min-width: 20em;
}
</style>
