<template>
  <aside id="endorsement-pane">
    <div class="hero is-fullheight">
      <div class="hero-head has-padding-200 has-text-centered">
        <a
          href="https://fixed.net/"
          target="_blank"
          class="is-block has-margin-bottom-100"
        >
          <img
            src="/assets/images/interface/logos/fixed-reverse.svg"
            alt="Fixed"
            width="120"
          />
        </a>
        <img
          src="/assets/images/interface/fixed—billboard—fixing.png"
          alt="Fixed team"
        />
      </div>
      <div class="hero-foot has-padding-200 has-margin-top-300">
        <div class="has-text-white">
          <h2 class="title is-size-5 has-margin-bottom-100">
            <i class="fas fa-quote-right" /> I've been with these guys for a few
            months now, and I can't imagine my life without them. Absolute
            lifesavers, multiple times over - thank you to the team!
          </h2>
          <p>
            <em>
              –
              <strong>Kay</strong>, via TrustPilot
            </em>
          </p>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "EndorsementPane"
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
#endorsement-pane {
  grid-row: 1;
  background-color: $primary;
  box-shadow: 1px 0 1rem 0 rgba($black, 0.25);
  z-index: 1;
  @include is-scrollable;
  @include touch {
    display: none;
  }
  div.hero {
    background: rgba($primary, 0.3)
      linear-gradient(
        to bottom,
        rgba($primary, 0.66) 10%,
        rgba($primary, 0.4) 66%,
        rgba($black, 0.33) 100%
      );
  }
}
</style>
